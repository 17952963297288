const INITIALIZE_APP = "INITIALIZE_APP";
const API_ERROR = "API_ERROR";
const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
const AUTHENTICATE_USER = "AUTHENTICATE_USER";
const SET_USER = "SET_USER";
const SET_APP_THEME = "SET_APP_THEME";
const UPDATE_ONLINE_STATUS = "update_online_status";
const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";
const SET_SECURE_DOMAIN = "SET_SECURE_DOMAIN";
const SET_IS_MY_USER = "SET_IS_MY_USER";
const RESET_USER = "RESET_USER";
const SET_TRANSITION = "SET_TRANSITION";
const SET_IS_ACCOUNT_SUSPENDED = "SET_IS_ACCOUNT_SUSPENDED";
const SET_IS_ONE_TIME_USER = "SET_IS_ONE_TIME_USER";
const SET_UNAUTH_STATE = "APPBAR_SET_UNAUTH_STATE";
const SET_PUBLIC_PAGE = "APPBAR_SET_PUBLIC_PAGE";
const SET_ORGANISATION_SPECIFIC_DETAILS = "SET_ORGANISATION_SPECIFIC_DETAILS";
const SET_CREDIT_LIMIT_DETAILS = "SET_CREDIT_LIMIT_DETAILS";
const SET_IS_RESELLER_BOOKING = "SET_IS_RESELLER_BOOKING";

export default {
  INITIALIZE_APP,
  API_ERROR,
  CHANGE_LANGUAGE,
  AUTHENTICATE_USER,
  SET_USER,
  SET_APP_THEME,
  UPDATE_ONLINE_STATUS,
  SET_CREDIT_LIMIT_DETAILS,
  SET_WINDOW_SIZE,
  SET_SECURE_DOMAIN,
  SET_IS_MY_USER,
  RESET_USER,
  SET_TRANSITION,
  SET_IS_ACCOUNT_SUSPENDED,
  SET_IS_ONE_TIME_USER,
  SET_UNAUTH_STATE,
  SET_PUBLIC_PAGE,
  SET_ORGANISATION_SPECIFIC_DETAILS,
  SET_IS_RESELLER_BOOKING,
};
