import ACTION_TYPES from "./appActionTypes";

import { getCookie } from "./utils/cookieHelper";
import { getConfiguration } from "./utils/configurationUtil";
import {
  MY_ACCOUNT,
  MY_ACCOUNT_TYPE,
  USER_TYPE,
} from "./constants/configuration";

const marketFromCookie = () => {
  const market = getCookie("MarketPreference");
  return market || 1;
};

const initialUserState = {
  loggedInUser: undefined,
  isAuthenticated: false,
  isMyUser: undefined,
};

const initialState = {
  appTheme: USER_TYPE.BUSINESS,
  application: getConfiguration("application"),
  culture: {},
  departmentsList: [],
  gtmId: "",
  lang: "en",
  isAlive: true,
  isInitialized: false,
  marketProfileID: marketFromCookie(),
  marketProfileCountryCode: "",
  redirectToDefaultPage: getConfiguration("redirectToDefaultPage"),
  showSignUpLink: getConfiguration("showSignUpLink"),
  systemRecipients: [],
  windowSize: "",
  onLine: window.navigator.onLine,
  secureDomain: "",
  ...initialUserState,
  isInTransition: false,
  isAccountSuspended: false,
  isOneTimeUser: false,
  isPublicPage: false,
  isUnauthState: false,
  travelPolicyStatusTexts: {},
  creditLimitDetails: {},
  isResellerBooking: false,

  //server keys for gtm
  serverTimeStamp: "",
  trueClientIP: "",
  localLanguageSupport: "",
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE_APP:
      state = {
        ...state,
        anonymousEmail: action.payload.anonymousEmail,
        isAnonymousUser:
          !action.payload.isAuthenticated &&
          typeof action.payload.oneTimeUser === "undefined" &&
          typeof action.payload.anonymousEmail !== "undefined",
        culture: action.payload.culture,
        gtmId: action.payload.gTMID,
        isAccountSuspended: action.payload.isSuspended,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isMyUser:
          action.payload.loggedInUser &&
          (action.payload.loggedInUser.type === MY_ACCOUNT_TYPE || action.payload.loggedInUser.type === MY_ACCOUNT),
        isOneTimeUser:
          action.payload.oneTimeUser && action.payload.oneTimeUser != null,
        loggedInUser: action.payload.loggedInUser,
        marketProfileID: action.payload.marketProfileID,
        marketProfileCountryCode: action.payload.marketProfileCountryCode,
        oneTimeUser: action.payload.oneTimeUser,
        secureDomain: action.payload.secureDomain,
        homePageUrl: action.payload.homePageUrl,
        isB2BUser:
          action.payload.isAuthenticated &&
          action.payload.loggedInUser &&
          action.payload.loggedInUser.type !== MY_ACCOUNT &&
          !action.payload.loggedInUser.isMusafirStaff,
        systemRecipients: action.payload.systemRecipients,
        departmentsList: action.payload.departmentsList,
        travelPolicyStatusTexts: action.payload.travelPolicyStatusTexts,
        serverTimeStamp: action.payload.serverKeys.serverTimeStamp,
        trueClientIP: action.payload.serverKeys.trueClientIP,
        localLanguageSupport: action.payload.localLanguageSupport,
      };
      break;
    case ACTION_TYPES.SET_ORGANISATION_SPECIFIC_DETAILS:
      state = {
        ...state,
        systemRecipients: action.payload.systemRecipients,
        departmentsList: action.payload.departmentsList,
        travelPolicyStatusTexts: action.payload.travelPolicyStatusTexts,
      };
      break;
    case ACTION_TYPES.SET_UNAUTH_STATE:
      state = {
        ...state,
        isUnauthState: action.payload,
      };
      break;
    case ACTION_TYPES.SET_PUBLIC_PAGE:
      state = {
        ...state,
        isPublicPage: action.payload,
      };
      break;
    case ACTION_TYPES.API_ERROR:
      state = {
        ...state,
        isAlive: false,
        isInitialized: true,
        isAuthenticated: false,
      };
      break;
    case ACTION_TYPES.CHANGE_LANGUAGE:
      state = {
        ...state,
        lang: state.lang === "en" ? "ar" : "en",
      };
      break;
    case ACTION_TYPES.AUTHENTICATE_USER:
      state = {
        ...state,
        isAuthenticated: action.payload,
      };
      break;
    case ACTION_TYPES.SET_USER:
      state = {
        ...state,
        loggedInUser: action.payload,
      };
      break;
    case ACTION_TYPES.UPDATE_ONLINE_STATUS:
      state = {
        ...state,
        onLine: window.navigator.onLine,
      };
      break;
    case ACTION_TYPES.SET_WINDOW_SIZE:
      state = {
        ...state,
        windowSize: action.payload,
      };
      break;
    case ACTION_TYPES.SET_IS_MY_USER:
      state = {
        ...state,
        isMyUser: action.payload,
      };
      break;
    case ACTION_TYPES.RESET_USER:
      state = {
        ...state,
        ...initialUserState,
      };
      break;
    case ACTION_TYPES.SET_TRANSITION:
      state = {
        ...state,
        isInTransition: action.payload,
      };
      break;
    case ACTION_TYPES.SET_IS_ACCOUNT_SUSPENDED:
      state = { ...state, isAccountSuspended: action.payload };
      break;
    case ACTION_TYPES.SET_IS_ONE_TIME_USER:
      state = { ...state, isOneTimeUser: action.payload };
      break;
    case ACTION_TYPES.SET_APP_THEME:
      state = {
        ...state,
        appTheme: action.payload,
      };
      break;
    case ACTION_TYPES.SET_CREDIT_LIMIT_DETAILS:
      state = {
        ...state,
        ...{
          creditLimitDetails: action.payload,
        },
      };
      break;
    case ACTION_TYPES.SET_IS_RESELLER_BOOKING:
      state = {
        ...state,
        isResellerBooking: action.payload,
      };
      break;
  }
  return state;
};

export default appReducer;
