import { ATTACHMENT } from "../constants/api";
import { VerifyEmailOTP } from "../signin/components/EmailOTPVerification/VerifyOTP";

import { apiGet, apiPost, apiPut, apiInstantUpload } from "../utils/api/apiRaw";
import { encodeUrl } from "../utils/api/hashQueryParameters";
import { getConfiguration } from "../utils/configurationUtil";

export const initApp = function (authenticationTokens) {
  let apiUrl = "initApp";
  apiUrl = encodeUrl(apiUrl, authenticationTokens, true, false);
  return apiGet(apiUrl, true);
};

export const isUserLoggedIn = function () {
  return apiGet("plus/IsUserLoggedIn");
};

export const forgotPassword = function (data) {
  return apiPut("forgotPassword", data);
};

export const validateUserName = function (userName) {
  let apiUrl = "plus/validateUserName";
  const data = { userName };
  apiUrl = encodeUrl(apiUrl, data);
  return apiGet(apiUrl);
};
export const userLogin = function (data) {
  return apiPost("plus/login", data);
};

export const emailOTPVerify = function (
  username,
  password,
  otp,
  verifyemailtoastMessage,
) {
  return VerifyEmailOTP(username, password, otp, verifyemailtoastMessage);
};

export const logToServer = function (data) {
  return apiPost("log", data);
};
export const validateSetPasswordTokenAndId = function (data) {
  return apiPost("validateTokenAndId", data);
};

export const onSetPassword = function (data) {
  return apiPost("setPassword", data);
};

export const user = function (data) {
  return apiGet(`plus/user${data}`);
};

export const organizationUserResetPassword = function (data) {
  return apiPut("organizationUserResetPassword", data);
};

export const myAccountResetPassword = function (data) {
  return apiPut("plus/myAccount/resetPassword", data);
};

export const userForgotPassword = function () {
  return apiPut("plus/userForgotPassword");
};

export const instantFileUpload = function (
  formData,
  fileNameArray,
  fileType,
  additionalParams,
  apiPath = ATTACHMENT.APP_ATTACHMENT,
  isMicroServiceApiCall = false,
  objectTypeId,
  objectId,
  typeId,
  isDraft = true,
) {
  const apiUrl = `${apiPath}/upload`;
  return apiInstantUpload(
    apiUrl,
    formData,
    fileNameArray,
    false,
    false,
    true,
    isMicroServiceApiCall,
    {
      tid: typeId,
      oid: objectId,
      otid: objectTypeId,
      draft: isDraft,
      utid: fileType,
      ...additionalParams,
    },
  );
};

export const instantFileDelete = function (
  data,
  apiPath = ATTACHMENT.APP_ATTACHMENT,
  isMicroServiceApiCall = false,
) {
  if (data.id) {
    return apiPost(
      `${apiPath}/delete?id=${data.id}`,
      data,
      true,
      false,
      false,
      isMicroServiceApiCall,
    );
  }
  else {
    return apiPost(
      `${apiPath}/delete`,
      data,
      true,
      false,
      false,
      isMicroServiceApiCall,
    );
  }
};

export const getCreditLimit = function (organizationId, shouldAbort = true) {
  const params = {
    OrganizationId: organizationId,
    initCreditLimit: true,
    initApprovalHierarchy: false,
  };
  let apiUrl = "/init";
  apiUrl = encodeUrl(apiUrl, params);

  if (!shouldAbort) return apiGet(apiUrl);

  return apiGet(apiUrl, true);
};

export const getOrganizationDetails = function (
  organizationId,
  data,
  shouldAbort = true,
) {
  const params = {
    OrganizationId: organizationId,
    initCreditLimit: data.initCreditLimit,
    initIsReseller: data.initIsReseller,
    initApprovalHierarchy: false,
  };
  let apiUrl = "/init";
  apiUrl = encodeUrl(apiUrl, params);

  if (!shouldAbort) return apiGet(apiUrl);

  return apiGet(apiUrl, true);
};
